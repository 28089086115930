<template>
  <DialogTabsContainer
    v-model="userStore.dialog"
    :is-loading="userStore.isLoading"
    :current-tab.sync="userStore.currentTab"
    :inline="inline"
    @close="userStore.closeDialog"
  >
    <template #header>
      <UserDialogHeader :inline="inline" />
    </template>
    <template #v-tabs-content>
      <v-tab key="SUMMARY">SUMMARY</v-tab>
      <v-tab key="EVENTS">EVENTS</v-tab>

      <v-tabs-items v-model="userStore.currentTab" class="ml-2">
        <v-tab-item key="SUMMARY">
          <ERow no-gutters>
            <ECol :cols="$vuetify.breakpoint.lgAndUp ? 6 : 12">
              <UsersDetails :user-details="userDetails" />
            </ECol>
            <v-divider :vertical="$vuetify.breakpoint.lgAndUp" />
            <ECol :cols="$vuetify.breakpoint.lgAndUp ? 6 : 12">
              <UserAccessFeaturesSelector v-model="userStore.userDetails" />
            </ECol>
          </ERow>
          <UsersProjectsTable
            :user-id="userDetails.id"
            :company-id="userDetails.companyId"
          />
          <UsersCamerasTable
            :user-id="userDetails.id"
            :api-id="userDetails.userApiId"
            :api-key="userDetails.userApiKey"
          />
        </v-tab-item>
        <v-tab-item key="EVENTS">
          <PaEventsStats :id="userDetails.id" :events-of="'users'" />
          <UsersEventsTable :user-id="userDetails.id" />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </DialogTabsContainer>
</template>

<script>
import DialogTabsContainer from "@/components/DialogTabsContainer"
import UsersDetails from "@/components/users/UsersDetails"
import UsersProjectsTable from "@/components/users/UsersProjectsTable"
import UsersCamerasTable from "@/components/users/UsersCamerasTable"
import UsersEventsTable from "@/components/users/UsersEventsTable"
import UserDialogHeader from "@/components/users/UserDialogHeader"
import UserAccessFeaturesSelector from "@/components/users/UserAccessFeaturesSelector"
import PaEventsStats from "@/components/pa/PaEventsStats"
import { mapStores } from "pinia"
import { useUserStore } from "@/stores/users"

export default {
  name: "UserDialog",
  components: {
    DialogTabsContainer,
    UsersDetails,
    UsersProjectsTable,
    UsersCamerasTable,
    UsersEventsTable,
    PaEventsStats,
    UserAccessFeaturesSelector,
    UserDialogHeader,
  },
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    ...mapStores(useUserStore),
    userDetails() {
      return this.userStore.userDetails
    },
  },
  watch: {
    inline: {
      handler(value) {
        if (value && this.userId) {
          this.userStore.fetchUserDetails(this.userId)
        }
      },
      immediate: true,
    },
  },
}
</script>
