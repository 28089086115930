<template>
  <div>
    <div class="d-flex justify-between alig-center">
      <div class="subtitle-2 pl-4">Features access</div>
      <EditingButton
        :can-edit="canEdit"
        :is-saving="isUpdatingFeatureFlags"
        @start-editing="onStartEditing"
        @save="onSaveFeatureFlags"
        @cancel="onCancelEditing"
      />
    </div>
    <FeatureFlagsTree
      v-if="selectedFeatureFlags"
      v-model="selectedFeatureFlags"
      :items="featureFlags"
      :readonly="isUpdatingFeatureFlags || !canEdit"
    />
  </div>
</template>

<script>
import FeatureFlagsTree from "@/components/FeatureFlagsTree"
import EditingButton from "@/components/EditingButton"
import { USER_ACCESS_FEATURES } from "@/components/constants"
import { AdminApi } from "@evercam/shared/api/adminApi"

export default {
  name: "UserAccessFeaturesSelector",
  components: {
    FeatureFlagsTree,
    EditingButton,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isUpdatingFeatureFlags: false,
      canEdit: false,
      oldSelectedFeatureFlags: [],
      featureFlags: USER_ACCESS_FEATURES,
    }
  },
  computed: {
    selectedFeatureFlags: {
      get() {
        return this.value?.features
      },
      set(features) {
        this.$emit("input", { ...this.value, features })
      },
    },
  },
  methods: {
    async updateUser() {
      try {
        await AdminApi.users.updateUser(this.value.id, {
          features: this.selectedFeatureFlags,
        })
        this.$notifications.success(
          "User access to features updated successfully"
        )
      } catch (error) {
        this.$notifications.error({
          text: "Failed to update user access to features!",
          error,
        })
      }
    },
    async onSaveFeatureFlags() {
      this.isUpdatingFeatureFlags = true
      await this.updateUser()
      this.canEdit = false
      this.isUpdatingFeatureFlags = false
      this.oldSelectedFeatureFlags = []
    },
    onStartEditing() {
      this.oldSelectedFeatureFlags = [...this.selectedFeatureFlags]
      this.canEdit = true
    },
    onCancelEditing() {
      this.selectedFeatureFlags = [...this.oldSelectedFeatureFlags]
      this.canEdit = false
    },
  },
}
</script>
